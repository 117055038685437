export class FetchCodeListPersonalRelationship {
  static readonly type = '[CodeList] Fetch code list personal relationship';
}

export class FetchCodeListLanguages {
  static readonly type = '[CodeList] Fetch code list languages';
}

export class FetchCodeListOccupation {
  static readonly type = '[CodeList] Fetch code list occupation';
}
