import {ChangeDetectionStrategy, Component, input, InputSignal, signal, WritableSignal} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs';
import {JsonPipe} from '@angular/common';

export interface AutocompleteOption {
  id: string;
  value: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'msa-autocomplete',
  standalone: true,
  imports: [MatFormFieldModule, ReactiveFormsModule, MatAutocompleteModule, MatInputModule, JsonPipe],
  templateUrl: './msa-autocomplete.component.html'
})
export class MsaAutocompleteComponent {
  options: InputSignal<Array<AutocompleteOption>> = input.required<Array<AutocompleteOption>>();
  filterControl: InputSignal<FormControl> = input.required<FormControl>();
  filterValue: WritableSignal<string> = signal<string>('');

  filteredOptions = toSignal(
    toObservable(this.filterValue).pipe(
      map((filterValue: string) =>
        this.options().filter(option => option.value.toLowerCase().includes(filterValue.toLowerCase()))
      ),
      takeUntilDestroyed()
    )
  );

  displayFn: (option: AutocompleteOption) => string = (option: AutocompleteOption) => {
    return option?.value ?? '';
  };
}
