<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="customerNumberForm.invalid"
  [footNote]="'i18n.common.mandatory'"
  [title]="'i18n.person-data.dialogs.connect-swiss-pass.title'"
  (confirmEvent)="connectSwissPass($event)"
>
  @if (errorMessage() === HttpStatusCode.InternalServerError) {
    <app-msa-content-notice
      contentNoticeTitle="i18n.person-data.dialogs.connect-swiss-pass.customer-number-generic-error-title"
      type="Error"
    >
      {{ "i18n.person-data.dialogs.connect-swiss-pass.customer-number-generic-error" | safeTranslate }}
    </app-msa-content-notice>
  } @else {
    <form class="tw-mb-15 d:tw-mb-18">
      <msa-text-input
        id="swissPassInput"
        [dataCy]="'swissPassCustomerNumber'"
        [label]="'i18n.person-data.dialogs.connect-swiss-pass.customer-number' | safeTranslate"
        [formControl]="customerNumberForm"
        [ngxMask]="'000-000-000-0'"
        [ngxDropSpecialCharacters]="false"
        [placeholder]="'i18n.person-data.dialogs.connect-swiss-pass.customer-number-example' | safeTranslate"
      >
        <ng-container errorContainer>
          @if (customerNumberForm.touched && customerNumberForm.hasError("mask")) {
            <p class="msa-text-small tw-text-vermillion-light-2">
              {{ "i18n.person-data.dialogs.connect-swiss-pass.customer-number-format-error" | safeTranslate }}
            </p>
          }
        </ng-container>
      </msa-text-input>
    </form>

    <div>
      <div class="tw-mb-15 tw-flex tw-flex-col tw-gap-9 d:tw-mb-18 d:tw-flex-row d:tw-gap-12">
        <div class="tw-flex tw-justify-center">
          <img height="135" width="218" ngSrc="assets/images/swisspass/swisspass_card.svg" alt="SwissPass Logo" />
        </div>
        <p class="msa-text-small tw-text-black-light-5" data-cy="swissPassDescription">
          {{ "i18n.person-data.dialogs.connect-swiss-pass.swiss-pass-description" | safeTranslate }}
        </p>
      </div>

      <p class="msa-text">{{ "i18n.person-data.swiss-pass.link-question" | safeTranslate }}</p>
      <msa-arrow-link
        [externalLink]="'https://www.swisspass.ch/home?lang=' + currentLanguage()"
        label="i18n.person-data.swiss-pass.link-to-order"
      />
    </div>
  }
</msa-dialog>
