<ng-content select="[header]" />

@for (yearGroup of sortedYears(); track yearGroup.year) {
  @if (yearGroup.year && yearGroup.year !== "NaN") {
    <p class="msa-text-small tw-mb-18 tw-font-bold" [class.mobile--future]="yearGroup.future">
      {{ yearGroup.year }}
    </p>
  }

  <div class="tw-mb-18 d:tw-ps-18">
    @for (item of timelineData()[yearGroup.year]; track item.id) {
      <div class="tw-flex tw-flex-col">
        <div class="mobile tw-flex tw-grid-cols-12">
          <div
            class="mobile--fixed-box mobile--fixed-box--margin tw-col-span-1 tw-flex tw-items-center d:tw-col-auto"
            [class.mobile--future]="true"
          >
            <div class="mobile--timeline-marker" [class.mobile--timeline-marker--future]="yearGroup.future"></div>
          </div>
          <div class="tw-flex-grow-1 tw-col-span-11 tw-w-full">
            <ng-container *ngTemplateOutlet="timelineItemTemplate(); context: { $implicit: item, index: $index }" />

            <!-- spacing between rows: must be done this way, because the timeline can have no gap -->
            @if (!$last) {
              <div class="tw-mb-12"></div>
            }
          </div>
        </div>
      </div>
    }
  </div>
} @empty {
  @if (textOnEmpty()) {
    <msa-empty-list-box [message]="textOnEmpty()" />
  }
}
