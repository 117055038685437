interface PhoneNumberProcessingResult {
  formattedNumber: string | null;
  errorMessage: string | null;
}

export function processPhoneNumber(phoneNumber: string): PhoneNumberProcessingResult {
  let errorMessage: string | null = null;
  let formattedNumber: string | null = phoneNumber;

  // Step 1: If the number starts with '00', replace '00' with '+'
  if (formattedNumber.startsWith('00')) {
    formattedNumber = formattedNumber.replace(/^00/, '+');
  }

  // Step 2: If the number does not start with '+', replace the leading '0' with '+41'
  if (!formattedNumber.startsWith('+')) {
    formattedNumber = formattedNumber.replace(/^0/, '+41');
  }

  // Step 3: If the number starts with '+41', check the string length (without '-', spaces, and parentheses)
  if (formattedNumber.startsWith('+41')) {
    const sanitizedNumber = formattedNumber.replace(/[-\s()]/g, '');
    const numberAfterPlus41 = sanitizedNumber.substring(3); // Remove '+41'
    if (numberAfterPlus41.length !== 9) {
      errorMessage =
        'When starting with +41, the phone number must have 9 digits after +41 (excluding "-", spaces, and parentheses).';
      return {formattedNumber: null, errorMessage};
    }

    // Step 4: Format the number to the following format: '+41 xx xxx xx xx'
    formattedNumber =
      '+41 ' +
      numberAfterPlus41.slice(0, 2) +
      ' ' +
      numberAfterPlus41.slice(2, 5) +
      ' ' +
      numberAfterPlus41.slice(5, 7) +
      ' ' +
      numberAfterPlus41.slice(7);
  } else {
    // Step 5: If the number does not start with '+41', it must have at least 10 and at most 24 characters
    const sanitizedNumber = formattedNumber.replace(/[-\s()]/g, '');
    if (sanitizedNumber.length < 10 || sanitizedNumber.length > 24) {
      errorMessage = 'The phone number must have at least 10 and at most 24 characters when not starting with +41.';
      return {formattedNumber: null, errorMessage};
    }
  }

  return {formattedNumber, errorMessage};
}
