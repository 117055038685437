import {HttpErrorResponse} from '@angular/common/http';
import {DestroyRef, Injectable, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Action, State, StateContext} from '@ngxs/store';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {
  FetchCodeListLanguages,
  FetchCodeListOccupation,
  FetchCodeListPersonalRelationship
} from '@shared/shared-module/stores/actions/code-list.action';
import {EMPTY, Observable, catchError, tap} from 'rxjs';
import {CodeDto, CodeRestService} from '../../../../../projects/person-data/src/app/core/api/generated/msa-person-data';

export interface CodeListStateModel {
  personalRelationship: Array<CodeDto>;
  languages: Array<CodeDto>;
  occupation: Array<CodeDto>;
  errorMessage: string;
}

@State<CodeListStateModel>({
  name: 'codeListRoot',
  defaults: {
    personalRelationship: [],
    languages: [],
    occupation: [],
    errorMessage: ''
  }
})
@Injectable()
export class CodeListState {
  private codeRestService: CodeRestService = inject(CodeRestService);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private errorMessageHandler: ErrorMessageHandler = inject(ErrorMessageHandler);

  @Action(FetchCodeListPersonalRelationship)
  getCodeListPersonalRelationship(ctx: StateContext<CodeListStateModel>) {
    return this.codeRestService.getRelationshipCodes().pipe(
      tap(result => {
        ctx.patchState({personalRelationship: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error, ctx);
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchCodeListLanguages)
  getCodeListLanguages(ctx: StateContext<CodeListStateModel>) {
    return this.codeRestService.getLanguageCodes().pipe(
      tap(result => {
        ctx.patchState({languages: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error, ctx);
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchCodeListOccupation)
  getCodeListOccupation(ctx: StateContext<CodeListStateModel>) {
    return this.codeRestService.getOccupationCodes().pipe(
      tap(result => {
        ctx.patchState({occupation: result});
      }),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.handleError(error, ctx);
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private handleError(error: unknown, ctx: StateContext<CodeListStateModel>): Observable<never> {
    const errorMessage = 'i18n.person-data.inline-warning-message';
    ctx.patchState({errorMessage});
    return this.errorMessageHandler.logAndIgnore(error);
  }
}
