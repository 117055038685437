<div class="tw-px-21 tw-py-30 d:tw-col-span-2 d:tw-px-45 d:tw-py-60">
  <div class="tw-flex tw-flex-col tw-gap-y-15 d:tw-gap-y-18">
    <h2 class="msa-text-h2">{{ "i18n.contactDetailsPopup.title" | safeTranslate }}</h2>
    <div>
      <p>{{ "i18n.contactDetailsPopup.info1" | safeTranslate }}</p>
      <p>{{ "i18n.contactDetailsPopup.info2" | safeTranslate }}</p>
    </div>
    <div>
      <h3 class="msa-text-h3 tw-mb-9 d:tw-mb-15">
        {{ "i18n.contactDetailsPopup.phoneNumbersTitle" | safeTranslate }} *
      </h3>
      <div class="tw-grid tw-bg-black-light-1.5 tw-px-18 tw-py-24 d:tw-grid-cols-3 d:tw-p-30">
        @if (phoneNumbersByType()) {
          <dl
            class="msa-text tw-grid tw-content-start d:tw-col-span-2 d:tw-gap-y-18 [&>*:not(:last-child)]:max-d:tw-mb-18 [&>*:not(:last-child)]:max-d:tw-border-b [&>*:not(:last-child)]:max-d:tw-pb-15 [&>*]:tw-border-black-light-3"
          >
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!phoneNumbersByType()!.CELL.number"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.phone-numbers.mobile-private" | safeTranslate }}
              </dt>
              <dd class="tw-flex tw-gap-12">
                {{ phoneNumbersByType()!.CELL.number }}
                @if (phoneNumbersByType()!.CELL.preferred) {
                  <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
                }
              </dd>
            </div>
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!phoneNumbersByType()!.CELP.number"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.phone-numbers.mobile-business" | safeTranslate }}
              </dt>
              <dd class="tw-flex tw-gap-12">
                {{ phoneNumbersByType()!.CELP.number }}
                @if (phoneNumbersByType()!.CELP.preferred) {
                  <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
                }
              </dd>
            </div>
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!phoneNumbersByType()!.HOME.number"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.phone-numbers.private" | safeTranslate }}
              </dt>
              <dd>
                {{ phoneNumbersByType()!.HOME.number }}
              </dd>
            </div>
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!phoneNumbersByType()!.BUSN.number"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.phone-numbers.business" | safeTranslate }}
              </dt>
              <dd>
                {{ phoneNumbersByType()!.BUSN.number }}
              </dd>
            </div>
          </dl>
        } @else {
          <p class="msa-text d:tw-col-span-2" [class.tw-text-vermillion-light-2]="phoneOrEmailsMissing()">
            {{ "i18n.contactDetailsPopup.noPhones" | safeTranslate }}
          </p>
        }
        <div class="max-d:tw-mt-30 d:tw-flex d:tw-items-end d:tw-justify-end">
          <msa-button (click)="openPhoneEditDialog()" widthClass="tw-w-full d:tw-w-auto" color="secondary">
            {{ "i18n.contactDetailsPopup.phoneNumbersButton" | safeTranslate }}
          </msa-button>
        </div>
      </div>
      <p
        class="msa-text-small tw-mt-9 tw-text-vermillion-light-2 d:tw-mt-15"
        [class.tw-hidden]="!(phoneOrEmailsMissing() && !phoneNumbersByType())"
      >
        {{ "i18n.contactDetailsPopup.noPhonesFootNote" | safeTranslate }}
      </p>
    </div>
    <div>
      <h3 class="msa-text-h3 tw-mb-9 d:tw-mb-15">
        {{ "i18n.contactDetailsPopup.emailAddressesTitle" | safeTranslate }} *
      </h3>
      <div class="tw-grid tw-bg-black-light-1.5 tw-px-18 tw-py-24 d:tw-grid-cols-3 d:tw-p-30">
        @if (emailsByType()) {
          <dl
            class="msa-text tw-grid tw-content-start d:tw-col-span-2 d:tw-gap-y-18 [&>*:not(:last-child)]:max-d:tw-mb-18 [&>*:not(:last-child)]:max-d:tw-border-b [&>*:not(:last-child)]:max-d:tw-pb-15 [&>*]:tw-border-black-light-3"
          >
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!emailsByType()!.HOME.address"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.emails.private" | safeTranslate }}
              </dt>
              <dd class="tw-flex tw-gap-12">
                {{ emailsByType()!.HOME.address }}
                @if (emailsByType()!.HOME.preferred) {
                  <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
                }
              </dd>
            </div>
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!emailsByType()!.BUSN.address"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.emails.business" | safeTranslate }}
              </dt>
              <dd class="tw-flex tw-gap-12">
                {{ emailsByType()!.BUSN.address }}
                @if (emailsByType()!.BUSN.preferred) {
                  <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
                }
              </dd>
            </div>
            <div
              class="tw-grid tw-grid-cols-1 d:tw-grid-cols-2 d:tw-gap-x-30"
              [class.max-d:tw-hidden]="!emailsByType()!.OTHR.address"
            >
              <dt class="tw-text-black-light-5.5 max-d:tw-pb-9">
                {{ "i18n.person-data.communication.emails.other" | safeTranslate }}
              </dt>
              <dd class="tw-flex tw-gap-12">
                {{ emailsByType()!.OTHR.address }}
                @if (emailsByType()!.OTHR.preferred) {
                  <mat-icon class="!tw-size-21 !tw-overflow-visible d:!tw-size-24" svgIcon="bookmark" />
                }
              </dd>
            </div>
          </dl>
        } @else {
          <p class="msa-text d:tw-col-span-2" [class.tw-text-vermillion-light-2]="phoneOrEmailsMissing()">
            {{ "i18n.contactDetailsPopup.noEmails" | safeTranslate }}
          </p>
        }
        <div class="max-d:tw-mt-30 d:tw-flex d:tw-items-end d:tw-justify-end">
          <msa-button (click)="openEmailEditDialog()" widthClass="tw-w-full d:tw-w-auto" color="secondary">
            {{ "i18n.contactDetailsPopup.emailAddressesButton" | safeTranslate }}
          </msa-button>
        </div>
      </div>
      <p
        class="msa-text-small tw-mt-9 tw-text-vermillion-light-2 d:tw-mt-15"
        [class.tw-hidden]="!(phoneOrEmailsMissing() && !emailsByType())"
      >
        {{ "i18n.contactDetailsPopup.noEmailsFootNote" | safeTranslate }}
      </p>
    </div>
    <div class="msa-text tw-flex tw-gap-x-6 tw-text-black-light-5">
      <mat-icon class="!tw-size-24" svgIcon="bookmark" />
      <p>{{ "i18n.contactDetailsPopup.iconNote" | safeTranslate }}</p>
    </div>
  </div>
  <div class="tw-my-30 tw-grid tw-gap-x-12 d:tw-my-60 d:tw-grid-cols-2">
    <msa-button class="d:tw-col-start-2" (click)="confirmContactDetails()">
      {{ "i18n.contactDetailsPopup.buttonText" | safeTranslate }}
    </msa-button>
  </div>
  <p class="msa-text tw-text-black-light-5">* {{ "i18n.contactDetailsPopup.footNote" | safeTranslate }}</p>
</div>
