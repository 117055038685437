<div
  class="tw-bg-black-light-1 tw-pb-30 tw-pe-21 tw-ps-21 tw-pt-30 d:tw-pb-60 d:tw-pe-45 d:tw-ps-45 d:tw-pt-60"
  [attr.data-cy]="latestDialogData().cypressTag"
>
  <h2 class="msa-text-h2 tw-mr-30">{{ latestDialogData().title | safeTranslate }}</h2>

  <div class="tw-absolute tw-right-12 tw-top-12 tw-text-2xl d:tw-right-24 d:tw-top-24">
    <button [mat-dialog-close]="false" aria-label="close dialog" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="tw-mt-15 tw-flex tw-max-h-[50vh] tw-flex-col tw-gap-12 tw-overflow-auto tw-pb-3 d:tw-mt-18">
    @if (latestDialogData().message) {
      <p class="msa-text">{{ latestDialogData().message | safeTranslate }}</p>
    }
    <ng-content />
  </div>

  <div class="tw-mt-30 !tw-grid tw-grid-cols-1 tw-gap-12 d:tw-mt-60 d:tw-grid-cols-2">
    @if (showCancelButton()) {
      <msa-button class="msa-text-small" (click)="onCancel()" color="secondary" data-cy="cancel">
        {{ latestDialogData().cancelButtonText | safeTranslate }}
      </msa-button>
    } @else {
      <div></div>
    }

    <msa-button
      class="msa-text-small"
      [loading]="isLoading()"
      [disabled]="latestDialogData()!.disabled ?? false"
      (click)="onConfirm()"
      color="primary"
      data-cy="confirm"
    >
      {{ latestDialogData().confirmButtonText | safeTranslate }}
    </msa-button>
  </div>

  @if (latestDialogData().footNote) {
    <p class="msa-text-small tw-mt-18 tw-text-black-light-5.5 d:tw-mt-18">
      {{ latestDialogData().footNote | safeTranslate }}
    </p>
  }
</div>
