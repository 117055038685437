import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {switchMap, take, tap} from 'rxjs';
import {AuthenticationService} from '../../core/services/authentication.service';
import {OnAuthenticated} from '../stores/actions/app.state.action';

export const authorizationGuard = (): CanActivateFn => {
  return (_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authenticationService = inject(AuthenticationService);
    const oidcSecurityService = inject(OidcSecurityService);
    const store = inject(Store);

    return oidcSecurityService.isAuthenticated().pipe(
      take(1),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          authenticationService.login({referralRoute: state.url});
        } else {
          store.dispatch(new OnAuthenticated());
        }
      }),
      switchMap(() => oidcSecurityService.isAuthenticated())
    );
  };
};
