import {Directive, inject, Injector, OnDestroy, OnInit, signal} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel
} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: HostControlDirective
    }
  ]
})
export class HostControlDirective<T> implements ControlValueAccessor, OnInit, OnDestroy {
  control = signal<FormControl<T>>(new FormControl());

  private injector = inject(Injector);
  private subscription?: Subscription;

  ngOnInit() {
    const ngControl = this.injector.get(NgControl, null, {self: true, optional: true});

    if (ngControl instanceof NgModel) {
      this.control.set(ngControl.control);
      this.subscription = ngControl.control.valueChanges.subscribe(value => {
        if (ngControl.model !== value || ngControl.viewModel !== value) {
          ngControl.viewToModelUpdate(value);
        }
      });
    } else if (ngControl instanceof FormControlDirective) {
      this.control.set(ngControl.control);
    } else if (ngControl instanceof FormControlName) {
      const container = this.injector.get(ControlContainer).control as FormGroup;
      this.control.set(container.controls[ngControl.name!] as FormControl);
    }
  }

  writeValue() {
    // do nothing
  }
  registerOnChange() {
    // do nothing
  }
  registerOnTouched() {
    // do nothing
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
