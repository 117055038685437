import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, effect, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngxs/store';
import {MsaAutocompleteComponent} from '@shared/shared-module/components/msa-autocomplete/msa-autocomplete.component';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {FetchCodeListPersonalRelationship} from '@shared/shared-module/stores/actions/code-list.action';
import {UpdateEmergencyContacts} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {CodeListSelectors} from '@shared/shared-module/stores/selectors/code-list.selectors';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {EmergencyContactDto, EmergencyContactUpdateDto} from '../../core/api/generated/msa-person-data';
import {processPhoneNumber} from '@shared/shared-module/functions/process-phone-number';
import {DatePipe} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {GERMAN_SHORT_DATE_FORMAT} from '@shared/shared-module/utils/date-time.utils';
import {getSeasonTranslation} from '@shared/shared-module/utils/season-utils';
import {emailValidator} from '@shared/shared-module/validators/email.validator';
import {phoneValidator} from '@shared/shared-module/validators/phone.validator';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MsaDialogComponent,
    ReactiveFormsModule,
    MatIconModule,
    SafeTranslatePipe,
    MsaAutocompleteComponent,
    MsaTextInputComponent,
    MsaButtonComponent,
    MsaCheckboxComponent,
    DatePipe,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule
  ],
  selector: 'msa-emergency-contacts-edit-dialog',
  standalone: true,
  templateUrl: './emergency-contacts-edit-dialog.component.html'
})
export class EmergencyContactsEditDialogComponent {
  private store: Store = inject(Store);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private changeDetectionRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  phoneRegex = /[^-0-9+()\s]/;

  contactForm = new FormGroup({
    contacts: new FormArray<FormGroup>([]),
    agreement: new FormControl(false)
  });

  emergencyContacts = readStoreSignal(PersonDataStateSelectors.getEmergencyContacts);
  personalRelationshipCodeList = readStoreSignal(CodeListSelectors.getPersonalRelationshipAutocompleteList);

  constructor() {
    this.store.dispatch(new FetchCodeListPersonalRelationship());

    const effectRef = effect(
      () => {
        const contacts = this.emergencyContacts();
        const relationshipCodes = this.personalRelationshipCodeList();

        if (contacts && relationshipCodes) {
          contacts.forEach(contact => {
            this.contactForm.controls.contacts.push(this.createContactGroup(contact));
          });

          if (this.contactForm.controls.contacts.length > 0) {
            this.contactForm.controls.agreement.setValidators([Validators.requiredTrue]);
            this.contactForm.controls.agreement.updateValueAndValidity();
          }
          this.changeDetectionRef.detectChanges();
          effectRef.destroy();
        }
      },
      {manualCleanup: true}
    );
  }

  createContactGroup = (contact: EmergencyContactDto | undefined): FormGroup => {
    function atLeastOnePhoneValidator(): ValidatorFn {
      return (group: AbstractControl): ValidationErrors | null => {
        const phoneNumber = group.get('phoneNumber')?.value;
        const phoneMobile = group.get('phoneMobile')?.value;
        return phoneNumber || phoneMobile ? null : {atLeastOnePhone: true};
      };
    }

    const selectedPersonalRelation = this.personalRelationshipCodeList()?.find(
      value => value.id === contact?.personalRelation?.codeHash
    );

    const formGroup = new FormGroup(
      {
        name: new FormControl(contact?.name, [Validators.required, Validators.maxLength(50)]),
        personalRelation: new FormControl(selectedPersonalRelation ?? '', [Validators.required]),
        address1: new FormControl(contact?.address1, [Validators.maxLength(55)]),
        address2: new FormControl(contact?.address2, [Validators.maxLength(55)]),
        zipCode: new FormControl(contact?.zipCode, [Validators.maxLength(12)]),
        city: new FormControl(contact?.city, [Validators.maxLength(30)]),
        phoneNumber: new FormControl(contact?.phoneNumber, [phoneValidator()]),
        phoneMobile: new FormControl(contact?.phoneMobile, [phoneValidator()]),
        email: new FormControl(contact?.email, [emailValidator()])
      },
      {validators: atLeastOnePhoneValidator()}
    );

    formGroup.controls.phoneNumber.valueChanges.subscribe(value => {
      const {formattedNumber} = processPhoneNumber(value ?? '');
      if (formattedNumber && formattedNumber !== value) {
        formGroup.controls.phoneNumber.setValue(formattedNumber, {emitEvent: false});
      }
    });
    formGroup.controls.phoneMobile.valueChanges.subscribe(value => {
      const {formattedNumber} = processPhoneNumber(value ?? '');
      if (formattedNumber && formattedNumber !== value) {
        formGroup.controls.phoneMobile.setValue(formattedNumber, {emitEvent: false});
      }
    });

    return formGroup;
  };

  addContact(): void {
    this.contactForm.controls.contacts.push(this.createContactGroup(undefined));
    if (this.contactForm.controls.contacts.length > 0) {
      this.contactForm.controls.agreement.setValidators([Validators.requiredTrue]);
      this.contactForm.controls.agreement.updateValueAndValidity();
    }
  }

  removeContact($index: number) {
    this.contactForm.controls.contacts.removeAt($index);
    if (this.contactForm.controls.contacts.length === 0) {
      this.contactForm.controls.agreement.removeValidators([Validators.requiredTrue]);
      this.contactForm.controls.agreement.updateValueAndValidity();
    }
  }

  updateEmergencyContacts($event: DialogConfirmEvent) {
    const payload: Array<EmergencyContactUpdateDto> = [];
    this.contactForm.controls.contacts.value.forEach((contactForm, index: number) => {
      const contact: EmergencyContactUpdateDto = {
        name: contactForm.name,
        personalRelationCodeHash: contactForm.personalRelation?.id,
        address1: contactForm.address1,
        address2: contactForm.address2,
        zipCode: contactForm.zipCode,
        city: contactForm.city,
        email: contactForm.email,
        phoneMobile: contactForm.phoneMobile?.trim() === '' ? null : contactForm.phoneMobile,
        phoneNumber: contactForm.phoneNumber?.trim() === '' ? null : contactForm.phoneNumber,
        priority: index
      };
      payload.push(contact);
    });
    this.store
      .dispatch(new UpdateEmergencyContacts(payload))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({next: $event.resolve, error: $event.reject});
  }

  protected readonly FormControl = FormControl;
  protected readonly Validators = Validators;
  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;
  protected readonly getSeasonTranslation = getSeasonTranslation;
}
