import {inject} from '@angular/core';
import {toObservable} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {CanActivateFn} from '@angular/router';
import {PopupsService} from '@shared/shared-module/services/popup/popups.service';
import {WalletPopupComponent} from '@shared/shared-module/components/popups/wallet-app/wallet-popup.component';
import {combineLatest, concat, filter, map, Observable, of, switchMap, take} from 'rxjs';
import {ContactDetailsPopupComponent} from '@shared/shared-module/components/popups/contact-details-popup/contact-details-popup.component';
import {ComponentType} from '@angular/cdk/overlay';
import {PopUpType} from '@shared/core/api/generated/msa-portal';
import {EmergencyContactPopupComponent} from '../components/popups/emergency-contact/emergency-contact-popup.component';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';

export const popupsGuard: CanActivateFn = () => {
  const popupsService = inject(PopupsService);
  const matDialog = inject(MatDialog);
  const featureFlagService = inject(FeatureFlagService);
  const popupsLoaded$ = toObservable(popupsService.popupsLoaded);
  const featureFlagsFetched$ = featureFlagService.featureFlagsFetched$;

  const popupConfigs: Array<{
    component: ComponentType<ContactDetailsPopupComponent | EmergencyContactPopupComponent | WalletPopupComponent>;
    type: PopUpType;
  }> = [];

  const handlePopups = (): Observable<boolean> => {
    return combineLatest([popupsLoaded$, featureFlagsFetched$]).pipe(
      filter(([popupsLoaded, flagsFetched]) => isDefined(popupsLoaded) && flagsFetched),
      take(1),
      switchMap(([popupsLoaded]) => {
        if (!popupsLoaded) return of(true);
        const popups = popupsService.popups();
        if (!popups) return of(true);

        if (featureFlagService.hasFeaturesEnabled('contactDataVerificationPopup')()) {
          popupConfigs.push({
            type: PopUpType.ContactDataVerification,
            component: ContactDetailsPopupComponent
          });
        }

        if (featureFlagService.hasFeaturesEnabled('emergencyContactDataVerificationPopup')()) {
          popupConfigs.push({
            type: PopUpType.EmergencyContactDataVerification,
            component: EmergencyContactPopupComponent
          });
        }

        popupConfigs.push({
          type: PopUpType.WalletInstallation,
          component: WalletPopupComponent
        });

        const popupsToShow = popups
          .map(popup => popupConfigs.find(config => config.type === popup.type))
          .reverse()
          .filter(isDefined)
          .slice(0, 2);

        const popupDialogs$ = popupsToShow.map(popup =>
          matDialog.open(popup.component as ComponentType<never>, {disableClose: true}).afterClosed()
        );

        // concat makes sure to show the dialogs in sequence and only complete
        // once all the dialogs have been processed
        return popupsToShow.length > 0 ? concat(popupDialogs$).pipe(map(() => true)) : of(true);
      })
    );
  };
  return handlePopups();
};
