import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  EmailUpdateDto,
  EmailVerifyDto,
  Language,
  PersonRestService,
  VerifyResponseDto
} from '../core/api/generated/msa-person-data';
import {EmailVerifyDialogComponent} from '../dialogs/email-verify-dialog/email-verify-dialog.component';
import {MsaDialogAction} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationService {
  private readonly personRestService = inject(PersonRestService);
  private readonly matDialog = inject(MatDialog);

  verifyEmailAddress(emailToValidate: EmailUpdateDto, language: Language): Observable<EmailUpdateDto> {
    const emailVerifyDto: EmailVerifyDto = {
      address: emailToValidate.address ?? '',
      language: language
    };

    return this.personRestService.verifyPersonEmails(emailVerifyDto).pipe(
      switchMap((emailVerifyResponseDto: VerifyResponseDto) => {
        if (!emailVerifyResponseDto.alreadyVerified) {
          if (!emailVerifyResponseDto.verificationCode) {
            return throwError(() => new Error('Undefined Verification Code!'));
          } else {
            return this.openVerificationDialog(emailVerifyDto, emailVerifyResponseDto.verificationCode).pipe(
              map(code => {
                if (!code) {
                  throw new Error('Verification cancelled or failed');
                }
                emailToValidate.verificationCode = code;
                return emailToValidate;
              })
            );
          }
        } else {
          emailToValidate.verificationCode = emailVerifyResponseDto.verificationCode;
          return of(emailToValidate);
        }
      }),
      catchError(error => {
        return throwError(() => error);
      })
    );
  }

  private openVerificationDialog(emailVerifyDto: EmailVerifyDto, verificationHash: string): Observable<string> {
    const dialogRef = this.matDialog.open(EmailVerifyDialogComponent, {
      data: {emailVerifyDto, verificationHash}
    });

    return dialogRef.beforeClosed().pipe(
      map(result => {
        if (result === MsaDialogAction.CONFIRM) {
          return dialogRef.componentInstance.emailVerifyForm.value.verificationCode ?? '';
        } else {
          return '';
        }
      })
    );
  }
}
