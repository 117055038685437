import {KeyValuePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {HostControlDirective} from '@shared/shared-module/directives/host-control.directive';

@Component({
  selector: 'msa-radiobutton',
  standalone: true,
  imports: [KeyValuePipe, TranslateModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './msa-radiobutton.component.html',
  hostDirectives: [HostControlDirective]
})
export class MsaRadiobuttonComponent<T> {
  public value = input.required<T>();
  public key = input.required<string>();
  public label = input<string>();
  public name = input<string>('');

  @Output() selected = new EventEmitter<T>();

  public hostControlDirective = inject(HostControlDirective);
}
