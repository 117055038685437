import {createPropertySelectors, Selector} from '@ngxs/store';
import {CodeListState, CodeListStateModel} from '@shared/shared-module/stores/states/code-list.state';
import {CodeDto, Language} from '../../../../../projects/person-data/src/app/core/api/generated/msa-person-data';
import {AutocompleteOption} from '@shared/shared-module/components/msa-autocomplete/msa-autocomplete.component';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {values} from 'lodash';

export class CodeListSelectors {
  static readonly slices = createPropertySelectors<CodeListStateModel>(CodeListState);

  @Selector([CodeListSelectors.slices.personalRelationship, AppStateSelectors.slices.language])
  static getPersonalRelationshipAutocompleteList(
    personalRelationship: Array<CodeDto>,
    language: Language
  ): Array<AutocompleteOption> {
    const personalRelationshipAutocompleteList: Array<AutocompleteOption> = [];
    personalRelationship.forEach(value => {
      personalRelationshipAutocompleteList.push(<AutocompleteOption>{
        id: value.codeHash,
        value: value.descriptionDto![language.toLowerCase() as keyof typeof values]
      });
    });
    return personalRelationshipAutocompleteList;
  }

  @Selector([CodeListSelectors.slices.languages, AppStateSelectors.slices.language])
  static getLanguagesAutocompleteList(languages: Array<CodeDto>, language: Language): Array<AutocompleteOption> {
    const languagesAutocompleteList: Array<AutocompleteOption> = [];
    languages.forEach(value => {
      languagesAutocompleteList.push(<AutocompleteOption>{
        id: value.codeHash,
        value: value.descriptionDto![language.toLowerCase() as keyof typeof values]
      });
    });
    return languagesAutocompleteList;
  }

  @Selector([CodeListSelectors.slices.occupation, AppStateSelectors.slices.language])
  static getOccupationAutocompleteList(occupation: Array<CodeDto>, language: Language): Array<AutocompleteOption> {
    const occupationAutocompleteList: Array<AutocompleteOption> = [];
    occupation.forEach(value => {
      occupationAutocompleteList.push(<AutocompleteOption>{
        id: value.codeHash,
        value: value.descriptionDto![language.toLowerCase() as keyof typeof values]
      });
    });
    return occupationAutocompleteList;
  }
}
