import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {createValidationError} from '@shared/shared-module/utils/validator.utils';

export function phoneValidator(): ValidatorFn {
  const PHONE_VALIDATOR_KEY = 'invalidPhone';
  return (control: AbstractControl): ValidationErrors | null => {
    const phoneRaw = control.value;
    if (!phoneRaw) {
      return null;
    }

    // Phone number validation is the same as in the msa-person-civil-data-service
    const stripped: string = phoneRaw.replaceAll(/\s/g, '');

    // Generic length requirement for all phone numbers
    if (stripped.length < 10 || stripped.length > 24) {
      return {
        [PHONE_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.phone-edit.error')
      };
    }

    if (!stripped.startsWith('+')) {
      return {
        [PHONE_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.phone-edit.error')
      };
    }

    // Filter Swiss phone numbers
    if (stripped.startsWith('+41') && stripped.length !== 12) {
      return {
        [PHONE_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.phone-edit.error')
      };
    }
    return null;
  };
}
