import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {createValidationError} from '@shared/shared-module/utils/validator.utils';

export function emailValidator(): ValidatorFn {
  const EMAIL_VALIDATOR_KEY = 'invalidEmail';
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(value)) {
      return {
        [EMAIL_VALIDATOR_KEY]: createValidationError('i18n.person-data.dialogs.email-edit.error')
      };
    }

    return null;
  };
}
