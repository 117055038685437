import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input, TemplateRef} from '@angular/core';
import {WithId} from '@shared/shared-module/utils/find.utils';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {chain} from 'lodash';
import moment from 'moment';
import {MsaEmptyListBoxComponent} from '../msa-empty-list-box/msa-empty-list-box.component';

export type TimelineData<T> = {
  [year: string]: T[];
};

@Component({
  selector: 'msa-timeline',
  templateUrl: './msa-timeline.component.html',
  styleUrl: './msa-timeline.component.css',
  standalone: true,
  imports: [CommonModule, MsaEmptyListBoxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsaTimelineComponent<T extends WithId> {
  public timelineData = input.required<TimelineData<T>>();
  public timelineItemTemplate = input.required<TemplateRef<unknown>>();
  public textOnEmpty = input<TranslationString>('');

  public sortedYears = computed(() => {
    const keys = Object.keys(this.timelineData());

    return chain(keys)
      .orderBy(item => item, 'desc')
      .map(year => ({year, future: this.isFutureYear(year)}))
      .value();
  });

  private currentYear = moment().year();

  private isFutureYear(year: string): boolean {
    const yearNumber = Number(year);
    return yearNumber > this.currentYear;
  }
}
