import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MsaDialogAction} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {
  Language,
  PersonRestService,
  PhoneUpdateDto,
  PhoneVerifyDto,
  VerifyResponseDto
} from '../core/api/generated/msa-person-data';
import {PhoneVerifyDialogComponent} from '../dialogs/phone-verify-dialog/phone-verify-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PhoneVerificationService {
  private readonly personRestService = inject(PersonRestService);
  private readonly matDialog = inject(MatDialog);

  verifyPhoneNumber(phoneToValidate: PhoneUpdateDto, language: Language): Observable<PhoneUpdateDto> {
    const phoneVerifyDto: PhoneVerifyDto = {
      number: phoneToValidate.number ?? '',
      language: language
    };
    return this.personRestService.verifyPersonPhone(phoneVerifyDto).pipe(
      switchMap((phoneVerifyResponseDto: VerifyResponseDto) => {
        if (!phoneVerifyResponseDto.alreadyVerified) {
          if (!phoneVerifyResponseDto.verificationCode) {
            throw new Error(`Undefined Verification Code!`);
          } else {
            return this.openVerificationDialog(phoneVerifyDto, phoneVerifyResponseDto.verificationCode).pipe(
              map(code => {
                if (!code) {
                  throw new Error('Verification cancelled or failed');
                }
                phoneToValidate.verificationCode = code;
                return phoneToValidate;
              })
            );
          }
        } else {
          phoneToValidate.verificationCode = phoneVerifyResponseDto.verificationCode;
          return of(phoneToValidate);
        }
      }),
      catchError(() => {
        return EMPTY;
      })
    );
  }

  private openVerificationDialog(phoneVerifyDto: PhoneVerifyDto, verificationHash: string): Observable<string> {
    const dialogRef = this.matDialog.open(PhoneVerifyDialogComponent, {
      data: {phoneVerifyDto, verificationHash}
    });

    return dialogRef.beforeClosed().pipe(
      map(result => {
        if (result === MsaDialogAction.CONFIRM) {
          return dialogRef.componentInstance.phoneVerifyForm.value.verificationCode ?? '';
        } else {
          return '';
        }
      })
    );
  }
}
