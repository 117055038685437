import {SeasonDto} from '../../../../projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {TranslationString} from './translation.utils';

export function getSeasonTranslation(season?: SeasonDto): TranslationString {
  switch (season) {
    case SeasonDto.Summer:
      return 'i18n.duties.gad.season.summer';
    case SeasonDto.Winter:
      return 'i18n.duties.gad.season.winter';
    case SeasonDto.Spring:
      return 'i18n.duties.gad.season.spring';
    case SeasonDto.Autumn:
      return 'i18n.duties.gad.season.autumn';
    default:
      throw new Error(`Unknown season ${season}!`);
  }
}
