<input
  class="tw-w-full tw-border tw-border-black-light-5 tw-bg-black-light-1 tw-px-15 tw-py-12 tw-text-white tw-outline-none focus:tw-border-white focus:tw-text-white d:tw-px-18 d:tw-py-15"
  #filterInput
  [matAutocomplete]="auto"
  [formControl]="filterControl()"
  [class.tw-border-vermillion]="filterControl().invalid"
  [class.tw-text-vermillion]="filterControl().invalid"
  (input)="filterValue.set(filterInput.value)"
  (focus)="filterValue.set(filterInput.value)"
  (blur)="filterValue.set(filterInput.value)"
  type="text"
  matInput
/>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [requireSelection]="true">
  @for (option of filteredOptions(); track option.id) {
    <mat-option [value]="option">
      {{ option.value }}
    </mat-option>
  }
</mat-autocomplete>
