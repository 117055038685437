import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneMask',
  standalone: true,
  pure: true
})
export class PhoneMaskPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const cleanedValue = value.replace(/[^0-9+]/g, '');
    const matched = cleanedValue.match(/^(\+?\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/);

    if (matched) {
      return `${matched[1]} ${matched[2]} ${matched[3]} ${matched[4]} ${matched[5]}`;
    }

    return value;
  }
}
