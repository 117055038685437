import {ChangeDetectionStrategy, Component, inject, OnInit, signal} from '@angular/core';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {MatIconModule} from '@angular/material/icon';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {PhoneEditDialogComponent} from '../../../../../../projects/person-data/src/app/dialogs/phone-edit-dialog/phone-edit-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EmailEditDialogComponent} from '../../../../../../projects/person-data/src/app/dialogs/email-edit-dialog/email-edit-dialog.component';
import {
  CommunicationRestService,
  EmailType,
  EmailUpdateDto,
  PhoneType,
  PhoneUpdateDto,
  VerificationStatusDto
} from '../../../../../../projects/person-data/src/app/core/api/generated/msa-person-data';
import {JsonPipe} from '@angular/common';
import {PhoneVerificationService} from '../../../../../../projects/person-data/src/app/services/phone-verification.service';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {EmailVerificationService} from '../../../../../../projects/person-data/src/app/services/email-verification.service';
import {Store} from '@ngxs/store';
import {FetchPersonData} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {PopupsService} from '@shared/shared-module/services/popup/popups.service';
import {PopUpAction, PopUpDto, PopUpStatus, PopUpType} from '@shared/core/api/generated/msa-portal';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {EMPTY} from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SafeTranslatePipe, MsaButtonComponent, MatIconModule, JsonPipe],
  selector: 'msa-contact-details',
  standalone: true,
  templateUrl: './contact-details-popup.component.html'
})
export class ContactDetailsPopupComponent implements OnInit {
  private readonly matDialog = inject(MatDialog);
  private readonly matDialogRef = inject(MatDialogRef);
  private readonly phoneVerificationService = inject(PhoneVerificationService);
  private readonly emailVerificationService = inject(EmailVerificationService);
  private readonly communicationRestService = inject(CommunicationRestService);
  private readonly store = inject(Store);
  private readonly popupsService = inject(PopupsService);
  phoneNumbersByType = readStoreSignal(PersonDataStateSelectors.getCommunicationPhonesByType);
  emailsByType = readStoreSignal(PersonDataStateSelectors.getCommunicationEmailsByType);
  currentLanguage = readStoreSignal(AppStateSelectors.slices.language);

  preferredPhoneVerificationStatus = signal<VerificationStatusDto | undefined>(undefined);
  preferredEmailVerificationStatus = signal<VerificationStatusDto | undefined>(undefined);

  phoneOrEmailsMissing = signal(false);

  ngOnInit() {
    this.store.dispatch(new FetchPersonData());
    this.communicationRestService.getCommunicationChannelsStatus().subscribe(communicationChannels => {
      this.preferredPhoneVerificationStatus.set(communicationChannels.preferredPhoneVerificationStatus);
      this.preferredEmailVerificationStatus.set(communicationChannels.preferredEmailVerificationStatus);
    });
  }

  openPhoneEditDialog() {
    this.matDialog.open(PhoneEditDialogComponent);
  }

  openEmailEditDialog() {
    this.matDialog.open(EmailEditDialogComponent);
  }

  confirmContactDetails() {
    this.communicationRestService.getCommunicationChannelsStatus().subscribe(communicationChannels => {
      this.preferredPhoneVerificationStatus.set(communicationChannels.preferredPhoneVerificationStatus);
      this.preferredEmailVerificationStatus.set(communicationChannels.preferredEmailVerificationStatus);

      if (!this.phoneNumbersByType() || !this.emailsByType()) {
        this.phoneOrEmailsMissing.set(true);
      } else if (this.preferredPhoneVerificationStatus() === VerificationStatusDto.NonePreferred) {
        this.matDialog.open(PhoneEditDialogComponent);
      } else if (this.preferredPhoneVerificationStatus() === VerificationStatusDto.NotVerified) {
        const preferredPhoneEntry = Object.entries(this.phoneNumbersByType()!).find(([_, phone]) => phone.preferred);
        const phoneToValidate: PhoneUpdateDto | undefined = preferredPhoneEntry
          ? {
              type: PhoneType[preferredPhoneEntry[0] as keyof typeof PhoneType],
              number: preferredPhoneEntry[1].number,
              preferred: preferredPhoneEntry[1].preferred
            }
          : undefined;
        if (phoneToValidate) {
          this.phoneVerificationService.verifyPhoneNumber(phoneToValidate, this.currentLanguage()).subscribe();
        }
      } else if (this.preferredEmailVerificationStatus() === VerificationStatusDto.NonePreferred) {
        this.matDialog.open(EmailEditDialogComponent);
      } else if (this.preferredEmailVerificationStatus() === VerificationStatusDto.NotVerified) {
        const preferredEmailEntry = Object.entries(this.emailsByType()!).find(([_, email]) => email.preferred);
        const emailToValidate: EmailUpdateDto | undefined = preferredEmailEntry
          ? {
              type: EmailType[preferredEmailEntry[0] as keyof typeof EmailType],
              address: preferredEmailEntry[1].address,
              preferred: preferredEmailEntry[1].preferred
            }
          : undefined;
        if (emailToValidate) {
          this.emailVerificationService.verifyEmailAddress(emailToValidate, this.currentLanguage()).subscribe();
        }
      } else {
        this.communicationRestService.confirmCommunicationChannels().subscribe({
          next: () => {
            const popupDto: PopUpDto = {
              type: PopUpType.ContactDataVerification,
              status: PopUpStatus.Dismissed,
              action: PopUpAction.ConfirmedDismissed
            };
            this.popupsService.dismissPopup(popupDto).subscribe(() => this.matDialogRef.close());
          },
          error: () => {
            return EMPTY;
          }
        });
      }
    });
  }
}
