<msa-card [cardTitle]="'i18n.person-data.contacts.title'" [menuItems]="menuItems">
  @if (emergencyContacts().length) {
    <div class="msa-text tw-grid tw-pb-18 tw-text-black-light-5.5 max-d:tw-hidden d:tw-grid-cols-21 d:tw-gap-x-30">
      <div class="tw-col-span-1 tw-hidden d:tw-grid"></div>
      <div class="tw-col-span-5">{{ "i18n.person-data.contacts.table.person" | safeTranslate }}</div>
      <div class="tw-col-span-5">
        {{ "i18n.person-data.contacts.table.relationship" | safeTranslate }}
      </div>
      <div class="tw-col-span-5">
        {{ "i18n.person-data.contacts.table.info" | safeTranslate }}
      </div>
      <div class="tw-col-span-5">
        {{ "i18n.person-data.contacts.table.address" | safeTranslate }}
      </div>
    </div>
    @for (emergencyContact of emergencyContacts(); track $index) {
      <div
        class="msa-text tw-grid tw-grid-cols-1 tw-border-black-light-3 max-d:tw-gap-y-18 max-d:odd:tw-mt-18 odd:max-d:tw-border-t odd:max-d:tw-pt-18 d:tw-grid-cols-21 d:tw-gap-x-30 d:tw-border-t d:tw-py-18"
      >
        <div class="tw-hidden tw-gap-y-9 d:tw-col-span-1 d:tw-grid">{{ $index + 1 }}.</div>
        <div class="tw-grid tw-gap-y-9 d:tw-col-span-5" data-cy="name">
          <p class="tw-text-black-light-5.5 d:tw-hidden">
            <span>{{ "i18n.person-data.contacts.table.person" | safeTranslate }} {{ $index + 1 }}</span>
          </p>
          <p>
            {{ emergencyContact.name }}
          </p>
        </div>
        <div class="tw-grid tw-gap-y-9 d:tw-col-span-5" data-cy="relationship">
          <p class="tw-text-black-light-5.5 d:tw-hidden">
            {{ "i18n.person-data.contacts.table.relationship" | safeTranslate }}
          </p>
          {{ emergencyContact.personalRelation?.descriptionDto | translateObject }}
        </div>
        <div class="tw-grid tw-gap-y-9 d:tw-col-span-5 d:tw-gap-y-18">
          <p class="tw-text-black-light-5.5 d:tw-hidden">
            {{ "i18n.person-data.contacts.table.info" | safeTranslate }}
          </p>
          @if (emergencyContact.email) {
            <p class="tw-flex tw-gap-x-6" data-cy="email">
              <mat-icon class="!tw-size-21 d:!tw-size-24" svgIcon="mail" />
              <span>{{ emergencyContact.email }}</span>
            </p>
          }
          @if (emergencyContact.phoneMobile) {
            <p class="tw-flex tw-gap-x-6" data-cy="phoneMobile">
              <mat-icon class="!tw-size-21 d:!tw-size-24" svgIcon="mobile" />
              <span>
                {{ emergencyContact.phoneMobile | phoneMask }}
              </span>
            </p>
          }
          @if (emergencyContact.phoneNumber) {
            <p class="tw-flex tw-gap-x-6" data-cy="telefon">
              <mat-icon class="!tw-size-21 d:!tw-size-24" svgIcon="telefon" />
              <span>
                {{ emergencyContact.phoneNumber | phoneMask }}
              </span>
            </p>
          }
        </div>
        <div class="tw-grid tw-gap-y-9 d:tw-col-span-5">
          <p class="tw-text-black-light-5.5 d:tw-hidden">
            {{ "i18n.person-data.contacts.table.address" | safeTranslate }}
          </p>
          <div>
            @if (emergencyContact.address1) {
              <p data-cy="address1">{{ emergencyContact.address1 }}</p>
            }
            @if (emergencyContact.address2) {
              <p data-cy="address2">{{ emergencyContact.address2 }}</p>
            }
            @if (emergencyContact.zipCode || emergencyContact.city) {
              <p data-cy="cityZipCode">{{ emergencyContact.zipCode }} {{ emergencyContact.city }}</p>
            }
          </div>
        </div>
      </div>
    }
  } @else {
    <p class="msa-text tw-text-black-light-5" data-cy="noEmergencyContacts">
      {{ "i18n.profile-settings-title.no-emergency-contacts" | safeTranslate }}
    </p>
  }
</msa-card>
