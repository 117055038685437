import {DestroyRef, inject, Injectable, signal} from '@angular/core';
import {
  AgreementDto,
  AgreementRestService
} from '../../../../../projects/person-data/src/app/core/api/generated/msa-person-data';
import {UTCdate} from '@shared/shared-module/utils/date-time.utils';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {catchError, EMPTY, map, Observable} from 'rxjs';
import {AuthenticationService} from '@shared/core/services/authentication.service';
import {HttpErrorResponse, HttpResponse, HttpStatusCode} from '@angular/common/http';
import {Router} from '@angular/router';
import {isDefined} from '@shared/shared-module/utils/is-defined';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  private _agreement = signal<boolean | null>(null);
  readonly agreement = this._agreement.asReadonly();
  readonly agreement$ = toObservable(this._agreement);
  private authenticationService = inject(AuthenticationService);
  private agreementRestService = inject(AgreementRestService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  constructor() {
    this.fetchAgreement();
  }

  fetchAgreement(): void {
    this.agreementRestService
      .getPersonAgreement('response')
      .pipe(
        map((httpResponse: HttpResponse<AgreementDto>) => {
          if (httpResponse.status === HttpStatusCode.NoContent) {
            this.router.navigate(['user-not-loaded']);
          }
          if (httpResponse.status === HttpStatusCode.Ok && isDefined(httpResponse.body)) {
            return httpResponse.body;
          }
          throw new HttpErrorResponse({status: httpResponse.status});
        }),
        catchError(() => EMPTY),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(body => this._agreement.set(body.accepted));
  }

  updateAgreement(agreement: boolean): Observable<boolean> {
    const agreementDto: AgreementDto = {
      accepted: agreement,
      dateTimeChanged: UTCdate()
    };
    return this.agreementRestService.updatePersonAgreement(agreementDto).pipe(
      map(result => {
        this._agreement.set(result.accepted);
        if (!result.accepted) {
          this.authenticationService.logout();
          return false;
        }
        return true;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
