import {DestroyRef, inject, Injectable, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {catchError, EMPTY, map, Observable, tap} from 'rxjs';
import {PopUpDto, PopupsRestService, PopUpStatus} from '@shared/core/api/generated/msa-portal';

@Injectable({
  providedIn: 'root'
})
export class PopupsService {
  private readonly popupsRestService = inject(PopupsRestService);
  private readonly destroyRef = inject(DestroyRef);

  activeStatuses = new Set([PopUpStatus.Mandatory, PopUpStatus.Dismissible]);
  public popups = signal<Array<PopUpDto> | null>(null);
  private _popupsLoaded = signal<boolean | undefined>(undefined); // undefined means, not yet fetched
  public popupsLoaded = this._popupsLoaded.asReadonly();

  constructor() {
    this.fetchPopups();
  }

  fetchPopups() {
    this.popupsRestService
      .getPopups()
      .pipe(
        tap(() => this._popupsLoaded.set(true)),
        map(popups => popups.filter(popup => this.activeStatuses.has(popup.status!))),
        catchError(() => {
          this._popupsLoaded.set(false);
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(body => this.popups.set(body));
  }

  dismissPopup(popup: PopUpDto): Observable<PopUpDto> {
    return this.popupsRestService.dismissPopup(popup).pipe(
      tap(result => {
        this.popups.update(currentPopups => currentPopups!.filter(p => p.type !== result.type));
      })
    );
  }
}
