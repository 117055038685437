<msa-dialog
  [confirmButtonText]="'i18n.common.save'"
  [disabled]="emailVerifyForm.invalid"
  [footNote]="'i18n.common.mandatory'"
  [title]="title"
  (confirmEvent)="verifyCode($event)"
>
  <p class="msa-text">
    <span>{{ "i18n.person-data.dialogs.email-verify.description.1" | safeTranslate }}</span>
    <strong> {{ data.emailVerifyDto.address }} </strong>
    <span>{{ "i18n.person-data.dialogs.email-verify.description.2" | safeTranslate }}</span>
  </p>

  <div class="tw-flex tw-flex-col tw-gap-y-18">
    <form class="tw-mt-60" [formGroup]="emailVerifyForm">
      <msa-text-input
        id="verificationCode"
        formControlName="verificationCode"
        ngxMask="AAAAAA"
        label="i18n.person-data.dialogs.email-verify.hint"
      />
    </form>

    <msa-arrow-link (click)="resendVerificationEmail()" label="i18n.person-data.dialogs.email-verify.sendNewCode" />
    <msa-arrow-link (click)="onCancel()" label="i18n.person-data.dialogs.email-verify.changeEmail" />
  </div>
</msa-dialog>
