import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MatDialogClose} from '@angular/material/dialog';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MatButtonModule} from '@angular/material/button';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeTranslatePipe, MsaCollapsibleComponent, MsaContentNoticeComponent, MatDialogClose, MatButtonModule]
})
export class AgreementComponent {}
