<div class="tw-bg-black-light-0.5 tw-px-21 tw-py-30 d:tw-col-span-2 d:tw-px-45">
  <h2 class="msa-text-h2">{{ "i18n.emergencyContactPopup.title" | safeTranslate }}</h2>
  @if (emergencyContacts().length) {
    <div class="tw-my-21 tw-flex tw-flex-col tw-gap-y-6 d:tw-gap-y-9">
      <p class="msa-text">{{ "i18n.emergencyContactPopup.paragraph-1" | safeTranslate }}</p>
      <p class="msa-text">{{ "i18n.emergencyContactPopup.paragraph-2" | safeTranslate }}</p>
    </div>
    <h3 class="msa-text-h3 tw-mb-15">{{ "i18n.emergencyContactPopup.table-header" | safeTranslate }}</h3>
    <div class="tw-bg-black-light-1 tw-p-30">
      <div
        class="msa-text d:tw-mt-50 tw-ml-50 tw-grid tw-pb-18 tw-text-black-light-5.5 max-d:tw-hidden d:tw-grid-cols-21 d:tw-gap-x-30"
      >
        <div class="tw-col-span-1"></div>
        <div class="tw-col-span-5">
          {{ "i18n.person-data.contacts.table.person" | safeTranslate }}
        </div>
        <div class="tw-col-span-5">
          {{ "i18n.person-data.contacts.table.relationship" | safeTranslate }}
        </div>
        <div class="tw-col-span-5">
          {{ "i18n.person-data.contacts.table.info" | safeTranslate }}
        </div>
        <div class="tw-col-span-5">
          {{ "i18n.person-data.contacts.table.address" | safeTranslate }}
        </div>
      </div>
      @for (emergencyContact of emergencyContacts(); track $index) {
        <div
          class="msa-text tw-grid tw-grid-cols-1 tw-border-black-light-3 max-d:tw-gap-y-18 max-d:odd:tw-mt-18 odd:max-d:tw-border-t odd:max-d:tw-pt-18 d:tw-grid-cols-21 d:tw-gap-x-30 d:tw-border-t d:tw-py-18 last-of-type:d:tw-border-b"
        >
          <div class="tw-grid tw-gap-y-9 d:tw-col-span-1">{{ $index + 1 }}.</div>
          <div class="tw-grid tw-gap-y-9 d:tw-col-span-5" data-cy="name">
            <p class="tw-text-black-light-5.5 d:tw-hidden">
              <span>{{ "i18n.person-data.contacts.table.person" | safeTranslate }}</span>
            </p>
            <p>
              {{ emergencyContact.name }}
            </p>
          </div>
          <div class="tw-grid tw-gap-y-9 d:tw-col-span-5" data-cy="relationship">
            <p class="tw-text-black-light-5.5 d:tw-hidden">
              {{ "i18n.person-data.contacts.table.relationship" | safeTranslate }}
            </p>
            {{ emergencyContact.personalRelation?.descriptionDto | translateObject }}
          </div>
          <div class="tw-grid tw-gap-y-9 d:tw-col-span-5 d:tw-gap-y-18">
            <p class="tw-text-black-light-5.5 d:tw-hidden">
              {{ "i18n.person-data.contacts.table.info" | safeTranslate }}
            </p>
            @if (emergencyContact.email) {
              <p class="tw-flex tw-gap-x-6" data-cy="email">
                <mat-icon class="!tw-size-21 d:!tw-size-24" svgIcon="mail" />
                <span>{{ emergencyContact.email }}</span>
              </p>
            }
            @if (emergencyContact.phoneMobile) {
              <p class="tw-flex tw-gap-x-6" data-cy="phoneMobile">
                <mat-icon class="!tw-size-21 d:!tw-size-24" svgIcon="mobile" />
                <span>
                  {{ emergencyContact.phoneMobile }}
                </span>
              </p>
            }
            @if (emergencyContact.phoneNumber) {
              <p class="tw-flex tw-gap-x-6" data-cy="telefon">
                <mat-icon class="!tw-size-21 d:!tw-size-24" svgIcon="telefon" />
                <span>
                  {{ emergencyContact.phoneNumber }}
                </span>
              </p>
            }
          </div>
          <div class="tw-grid tw-gap-y-9 d:tw-col-span-5">
            <p class="tw-text-black-light-5.5 d:tw-hidden">
              {{ "i18n.person-data.contacts.table.address" | safeTranslate }}
            </p>
            <div>
              @if (emergencyContact.address1) {
                <p data-cy="address1">{{ emergencyContact.address1 }}</p>
              }
              @if (emergencyContact.address2) {
                <p data-cy="address2">{{ emergencyContact.address2 }}</p>
              }
              @if (emergencyContact.zipCode || emergencyContact.city) {
                <p data-cy="cityZipCode">{{ emergencyContact.zipCode }} {{ emergencyContact.city }}</p>
              }
            </div>
          </div>
        </div>
      }
      <msa-button
        class="tw-mt-30 tw-flex tw-justify-end"
        (click)="openDialog(this.menuItem.component)"
        widthClass="tw-w-full d:tw-w-auto"
        color="secondary"
        data-cy="addContact"
      >
        <span>
          {{ "i18n.emergencyContactPopup.edit" | safeTranslate }}
        </span>
      </msa-button>
    </div>
  } @else {
    <div class="tw-my-21 tw-flex tw-flex-col tw-gap-y-6 d:tw-gap-y-9">
      <p class="msa-text">{{ "i18n.emergencyContactPopup.paragraph-1" | safeTranslate }}</p>
      <p class="msa-text" [innerHTML]="'i18n.emergencyContactPopup.paragraph-3' | safeTranslate"></p>
      <p class="msa-text">{{ "i18n.emergencyContactPopup.paragraph-4" | safeTranslate }}</p>
    </div>
    <app-msa-content-notice type="Info">
      {{ "i18n.emergencyContactPopup.info" | safeTranslate }}
    </app-msa-content-notice>
  }
  <div class="tw-mt-15 !tw-grid tw-grid-cols-1 tw-gap-12 d:tw-mt-30 d:tw-grid-cols-2">
    @if (emergencyContacts().length === 0) {
      <msa-button class="tw-py-6" (click)="closePopup()" color="secondary" data-cy="noInterest">
        <span>
          {{ "i18n.emergencyContactPopup.no-interest" | safeTranslate }}
        </span>
      </msa-button>
    }
    <msa-button
      class="tw-py-6 d:tw-col-start-2"
      (click)="handlePopup(this.menuItem.component)"
      color="primary"
      data-cy="addContact"
    >
      <span>
        {{
          emergencyContacts().length !== 0
            ? ("i18n.emergencyContactPopup.approve" | safeTranslate)
            : ("i18n.emergencyContactPopup.register" | safeTranslate)
        }}
      </span>
    </msa-button>
  </div>
  @if (emergencyContacts().length === 0) {
    <div class="tw-mt-12 tw-flex d:tw-mt-18">
      <msa-checkbox (checked)="toggleDismissChecked()" key="checkbox" label="i18n.emergencyContactPopup.checkbox" />
    </div>
  }
</div>
