import {CanActivateFn} from '@angular/router';
import {inject} from '@angular/core';
import {AgreementService} from '@shared/shared-module/services/agreement/agreement.service';
import {of, skipWhile, switchMap} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AgreementComponent} from '@shared/shared-module/components/agreement/agreement.component';

export const agreementGuard = (): CanActivateFn => {
  return () => {
    const agreementService = inject(AgreementService);
    const matDialog = inject(MatDialog);

    return agreementService.agreement$.pipe(
      skipWhile(result => result === null),
      switchMap(result => {
        if (result) {
          return of(true);
        }
        const matDialogRef = matDialog.open(AgreementComponent, {
          disableClose: true
        });
        return matDialogRef
          .afterClosed()
          .pipe(switchMap((hasAgreed: boolean) => agreementService.updateAgreement(hasAgreed)));
      })
    );
  };
};
