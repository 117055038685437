<div class="tw-bg-black-light-1 tw-px-21 tw-py-30 d:tw-px-45 d:tw-py-60" [attr.data-cy]="'agreementDialog'">
  <h2 class="msa-text-h2 tw-mb-15 d:tw-mb-18" data-cy="title">{{ "i18n.agreement.title" | safeTranslate }}</h2>
  <div class="tw-flex tw-max-h-[50vh] tw-flex-col tw-gap-y-15 tw-overflow-y-auto d:tw-gap-y-18">
    <p class="msa-text-small" data-cy="contentText">
      {{ "i18n.agreement.content.text" | safeTranslate }}
    </p>
    <div class="tw-border-b tw-border-black-light-4">
      <msa-collapsible [title]="'i18n.agreement.content.question'" data-cy="contentQuestion">
        <div class="tw-flex tw-flex-col tw-gap-y-18">
          <p>{{ "i18n.agreement.content.response.paragraph.1" | safeTranslate }}</p>
          <p>{{ "i18n.agreement.content.response.paragraph.2" | safeTranslate }}</p>
          <p>{{ "i18n.agreement.content.response.paragraph.3" | safeTranslate }}</p>
        </div>
      </msa-collapsible>
    </div>
    <app-msa-content-notice type="Info" data-cy="notice">
      {{ "i18n.agreement.content.declaration" | safeTranslate }}
    </app-msa-content-notice>
  </div>
  <div class="tw-mt-30 tw-flex tw-flex-col tw-gap-12 d:tw-mt-60 d:tw-flex-row">
    <button
      class="tw-w-full"
      [attr.data-cy]="'rejectAgreement'"
      [mat-dialog-close]="false"
      mat-flat-button
      color="accent"
      data-cy="reject"
    >
      {{ "i18n.agreement.actions.reject" | safeTranslate }}
    </button>
    <button
      class="tw-w-full"
      [attr.data-cy]="'acceptAgreement'"
      [mat-dialog-close]="true"
      mat-flat-button
      color="primary"
      data-cy="accept"
    >
      {{ "i18n.agreement.actions.accept" | safeTranslate }}
    </button>
  </div>
</div>
