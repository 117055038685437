import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'msa-arrow-link',
  standalone: true,
  imports: [TranslateModule, MatIconModule, RouterLink],
  templateUrl: './msa-arrow-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsaArrowLinkComponent {
  label = input.required<string>();
  showArrow = input(true);
  externalLink = input<string>('');
  colorStyling = input<string>('tw-text-blue-light-2');
  hoverStyling = input<string>('tw-text-blue-light-3');
}
