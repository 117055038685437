import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Store} from '@ngxs/store';
import {DialogConfirmEvent, MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {MsaRadiobuttonComponent} from '@shared/shared-module/components/msa-radiobutton/msa-radiobutton.component';
import {MsaTextInputComponent} from '@shared/shared-module/components/msa-text-input/msa-text-input.component';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {UpdateEmails} from '@shared/shared-module/stores/actions/person-data.state.actions';
import {AppStateSelectors} from '@shared/shared-module/stores/selectors/app.state.selectors';
import {
  CommunicationEmailsByType,
  PersonDataStateSelectors
} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {emailValidator} from '@shared/shared-module/validators/email.validator';
import {catchError, EMPTY, filter, Observable, of, switchMap, tap} from 'rxjs';
import {EmailType, EmailUpdateDto} from '../../core/api/generated/msa-person-data';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {EmailVerificationService} from '../../services/email-verification.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MsaDialogComponent, SafeTranslatePipe, ReactiveFormsModule, MsaTextInputComponent, MsaRadiobuttonComponent],
  selector: 'msa-email-edit-dialog',
  standalone: true,
  templateUrl: './email-edit-dialog.component.html'
})
export class EmailEditDialogComponent implements OnInit {
  private readonly store: Store = inject(Store);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly snackbarService = inject(SnackbarService);
  private readonly emailVerificationService = inject(EmailVerificationService);

  public language = toSignal(this.store.select(AppStateSelectors.slices.language), {
    initialValue: this.store.selectSnapshot(AppStateSelectors.slices.language)
  });

  title: TranslationString = 'i18n.person-data.dialogs.email-edit.title';

  emailForm = new FormGroup({
    privateEmail: new FormControl('', [emailValidator()]),
    businessEmail: new FormControl('', [emailValidator()]),
    otherEmail: new FormControl('', [emailValidator()]),
    preferredEmail: new FormControl('', Validators.required)
  });

  ngOnInit() {
    this.store
      .select(PersonDataStateSelectors.getCommunicationEmailsByType)
      .pipe(
        filter(isDefined),
        tap((emails: CommunicationEmailsByType) => this.populateEmailFields(emails)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.emailForm.controls.preferredEmail.valueChanges
      .pipe(
        filter(isDefined),
        tap((preferredEmail: string) => this.updateValidatorsOnPreferredChange(preferredEmail)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  populateEmailFields(emails: CommunicationEmailsByType) {
    this.emailForm.patchValue({
      privateEmail: emails.HOME.address,
      businessEmail: emails.BUSN.address,
      otherEmail: emails.OTHR.address,
      preferredEmail: emails.HOME.preferred
        ? 'privateEmailPreferred'
        : emails.BUSN.preferred
          ? 'businessEmailPreferred'
          : emails.OTHR.preferred
            ? 'otherEmailPreferred'
            : undefined
    });
  }

  updateValidatorsOnPreferredChange(preferredEmail: string) {
    const controls = this.emailForm.controls;
    controls.privateEmail.setValidators(emailValidator());
    controls.businessEmail.setValidators(emailValidator());
    controls.otherEmail.setValidators(emailValidator());

    if (preferredEmail === 'privateEmailPreferred') {
      controls.privateEmail.setValidators([Validators.required, emailValidator()]);
    } else if (preferredEmail === 'businessEmailPreferred') {
      controls.businessEmail.setValidators([Validators.required, emailValidator()]);
    } else if (preferredEmail === 'otherEmailPreferred') {
      controls.otherEmail.setValidators([Validators.required, emailValidator()]);
    }

    controls.privateEmail.updateValueAndValidity();
    controls.businessEmail.updateValueAndValidity();
    controls.otherEmail.updateValueAndValidity();
  }

  updateEmails($event: DialogConfirmEvent) {
    const privateEmailDto: EmailUpdateDto = {
      type: EmailType.Home,
      address: this.emailForm.value.privateEmail ?? '',
      preferred: this.emailForm.value.preferredEmail === 'privateEmailPreferred'
    };
    const businessEmailDto: EmailUpdateDto = {
      type: EmailType.Busn,
      address: this.emailForm.value.businessEmail ?? '',
      preferred: this.emailForm.value.preferredEmail === 'businessEmailPreferred'
    };
    const otherEmailDto: EmailUpdateDto = {
      type: EmailType.Othr,
      address: this.emailForm.value.otherEmail ?? '',
      preferred: this.emailForm.value.preferredEmail === 'otherEmailPreferred'
    };

    let emails: Array<EmailUpdateDto> = [privateEmailDto, businessEmailDto, otherEmailDto];
    emails = emails.filter(email => email.address && email.address.length > 0);

    const emailToValidate = emails.find(x => x.preferred === true);

    let verifyEmail$: Observable<EmailUpdateDto | null>;

    if (emailToValidate) {
      verifyEmail$ = this.emailVerificationService.verifyEmailAddress(emailToValidate, this.language());
    } else {
      verifyEmail$ = of(null);
    }

    verifyEmail$
      .pipe(
        switchMap(updatedEmail => {
          if (updatedEmail) {
            emails = emails.map(email =>
              email.address === emailToValidate?.address && email.preferred === true ? updatedEmail : email
            );
          }
          return this.store.dispatch(new UpdateEmails(emails));
        }),
        tap(() => {
          $event.resolve();
        }),
        catchError(error => {
          if (error.status === 429) {
            this.snackbarService.openSnackbar({
              text: 'i18n.common.error.tooManyRequests',
              type: MessageType.Error
            });
          } else {
            this.snackbarService.openSnackbar({text: 'i18n.common.error.generic', type: MessageType.Error});
          }

          $event.reject();
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
