import {KeyValuePipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  input,
  model,
  OnInit
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {HostControlDirective} from '@shared/shared-module/directives/host-control.directive';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [HostControlDirective],
  imports: [ReactiveFormsModule, NgxMaskDirective, KeyValuePipe, TranslateModule, MarkRequiredDirective],
  providers: [provideNgxMask()],
  selector: 'msa-text-input',
  standalone: true,
  templateUrl: './msa-text-input.component.html'
})
export class MsaTextInputComponent implements OnInit {
  id = input.required<string>();
  label = input<string>();
  name = input<string>();
  hint = input<string>();
  hideValidationErrors = input<boolean>(false);
  ngxMask = input<string>();
  ngxDropSpecialCharacters = input<boolean | null>(null);
  placeholder = input<string>();
  isActive = model<boolean>();
  inputClasses = input<string>();
  maxLength = input<number>();
  minLength = input<number>();
  dataCy = input<string>();
  cleanerRegex = input<string | RegExp>();

  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  protected hcd = inject(HostControlDirective);

  ngOnInit() {
    this.hcd
      .control()
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.changeDetectorRef.detectChanges());

    this.hcd
      .control()
      .statusChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  onBlur(): void {
    this.isActive.set(false);
  }

  onFocus(): void {
    this.isActive.set(true);
  }

  inputCleaner($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const cleanerRegex = this.cleanerRegex();
    if (cleanerRegex) {
      inputElement.value = inputElement.value.replace(cleanerRegex, '');
    }
  }
}
