import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Inject,
  input,
  Optional,
  Output
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {SafeTranslatePipe} from '../../pipes/safe-translate.pipe';

export interface MsaDialogData {
  disabled?: boolean;
  title?: TranslationString;
  message?: TranslationString;
  footNote?: TranslationString;
  cancelButtonText?: TranslationString;
  confirmButtonText?: TranslationString;
  showCancelButton?: boolean;
  cypressTag?: string;
}

export enum MsaDialogAction {
  CONFIRM,
  CANCEL
}

export interface DialogConfirmEvent {
  // I could not make it work with unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: (value?: any) => void;
  // I could not make it work with unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    SafeTranslatePipe,
    AsyncPipe,
    TranslateModule,
    MsaButtonComponent
  ],
  selector: 'msa-dialog',
  standalone: true,
  templateUrl: './msa-dialog.component.html'
})
export class MsaDialogComponent {
  @Output() confirmEvent = new EventEmitter<DialogConfirmEvent>();
  @Output() cancelEvent = new EventEmitter<MsaDialogComponent>();

  private dialogRef: MatDialogRef<MsaDialogAction> = inject(MatDialogRef);

  public disabled = input(false);
  public title = input<TranslationString>();
  public footNote = input<TranslationString>();
  public cancelButtonText = input<TranslationString>('i18n.common.discard');
  public confirmButtonText = input<TranslationString>('i18n.common.confirm');
  public showCancelButton = input(true);
  public isLoading = input(false);

  // dialog data should have precedence over inputs, since
  // dialog data means, that the component has been called in a very
  // specific setting, given arguments.
  public latestDialogData = computed<MsaDialogData>(() => ({
    disabled: this.data?.disabled ?? this.disabled(),
    title: this.data?.title ?? this.title(),
    message: this.data?.message,
    footNote: this.data?.footNote ?? this.footNote(),
    cancelButtonText: this.data?.cancelButtonText ?? this.cancelButtonText(),
    confirmButtonText: this.data?.confirmButtonText ?? this.confirmButtonText(),
    showCancelButton: this.data?.showCancelButton ?? this.showCancelButton(),
    cypressTag: this.data?.cypressTag ?? 'dialogWrapper'
  }));

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data?: MsaDialogData) {}

  onConfirm(): void {
    new Promise<void>((resolve, reject) => {
      this.confirmEvent.emit({resolve, reject});
    }).then(() => this.closeDialog(MsaDialogAction.CONFIRM));
  }

  closeDialog(dialogAction: MsaDialogAction): void {
    this.dialogRef.close(dialogAction);
  }

  onCancel(): void {
    this.cancelEvent.emit(this);
    this.closeDialog(MsaDialogAction.CANCEL);
  }
}
