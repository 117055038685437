import {ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit} from '@angular/core';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {QRCodeModule} from 'angularx-qrcode';
import {EmergencyContactsComponent} from '../../../../../../projects/person-data/src/app/components/emergency-contacts/emergency-contacts.component';
import {MatIconModule} from '@angular/material/icon';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {Store} from '@ngxs/store';
import {FetchCodeListPersonalRelationship} from '@shared/shared-module/stores/actions/code-list.action';
import {MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {MenuItem, MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {EmergencyContactsEditDialogComponent} from '../../../../../../projects/person-data/src/app/dialogs/emergency-contacts-edit-dialog/emergency-contacts-edit-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PopupsService} from '@shared/shared-module/services/popup/popups.service';
import {PopUpAction, PopUpDto, PopUpStatus, PopUpType} from '@shared/core/api/generated/msa-portal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SafeTranslatePipe,
    MsaButtonComponent,
    MsaCheckboxComponent,
    MsaContentNoticeComponent,
    NgOptimizedImage,
    QRCodeModule,
    EmergencyContactsComponent,
    MatIconModule,
    TranslateObjectPipe,
    MsaDialogComponent,
    MsaCardComponent,
    NgIf
  ],
  providers: [SafeTranslatePipe],
  selector: 'msa-emergency-contact-popup',
  standalone: true,
  templateUrl: './emergency-contact-popup.component.html'
})
export class EmergencyContactPopupComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly dialogRef = inject(MatDialogRef);
  private readonly matDialog = inject(MatDialog);
  private readonly popupsService = inject(PopupsService);
  private readonly destroyRef = inject(DestroyRef);

  private dismissChecked = false;
  footNote = input<TranslationString>('');
  footNoteBody = input<TranslationString>('');
  emergencyContacts = readStoreSignal(PersonDataStateSelectors.getEmergencyContacts);

  menuItem: MenuItem = {
    component: EmergencyContactsEditDialogComponent,
    menuItemTitle: 'i18n.person-data.dialogs.emergency-contacts.title'
  };

  ngOnInit(): void {
    this.store.dispatch(new FetchCodeListPersonalRelationship());
  }

  dismissEmergencyContactsPopup(popUpDtoActionEnum: PopUpAction) {
    const popupDto: PopUpDto = {
      type: PopUpType.EmergencyContactDataVerification,
      status: PopUpStatus.Inactive,
      action: popUpDtoActionEnum
    };
    this.popupsService
      .dismissPopup(popupDto)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dialogRef.close());
  }

  openDialog(menuItem: ComponentType<unknown>) {
    this.matDialog.open(menuItem);
  }

  handlePopup(menuItem?: ComponentType<unknown>) {
    if (this.emergencyContacts().length === 0) {
      this.matDialog.open(menuItem as ComponentType<EmergencyContactsEditDialogComponent>);
    } else {
      this.dismissEmergencyContactsPopup(PopUpAction.ConfirmedDismissed);
    }
  }

  closePopup() {
    if (this.dismissChecked) {
      this.dismissEmergencyContactsPopup(PopUpAction.PermanentlyDismissed);
    } else {
      this.dismissEmergencyContactsPopup(PopUpAction.TemporarilyDismissed);
    }
    this.dialogRef.close();
  }

  toggleDismissChecked() {
    this.dismissChecked = !this.dismissChecked;
  }
}
