/* eslint-disable @typescript-eslint/no-explicit-any */
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {every, isEmpty, isObject} from 'lodash';
import moment, {Moment} from 'moment';
import {isDefined} from './is-defined';
import {TranslationParams, TranslationString} from './translation.utils';

export interface ValidationError {
  text: TranslationString;
  params?: TranslationParams;
}

export type DateTimeRange = {
  fromDate: string;
  fromTime?: string;
  toDate: string;
  toTime?: string;
};

export function createValidationError(text: TranslationString, params?: TranslationParams): ValidationError {
  return {text, params};
}

function isValueDefinedAndNotEmpty(value: number | string): boolean {
  if (!isDefined(value)) return false;

  if (typeof value === 'string') {
    return !isEmpty(value);
  }

  return true;
}

/**
 * This function recursively asserts that all fields of the given object to
 * are defined and not empty (in case of strings).
 * @param obj Object to check
 * @returns True if all fields are set and strings are not empty
 */
export function allFieldsSetAndNotEmpty(obj: any): boolean {
  if (isObject(obj)) {
    return every(obj, (value: any) => {
      if (isObject(value)) {
        return allFieldsSetAndNotEmpty(value);
      }

      return isValueDefinedAndNotEmpty(value);
    });
  }

  return isValueDefinedAndNotEmpty(obj);
}

export class MsaValidators {
  static atLeastOneSelectedValidator: ValidatorFn = (control: AbstractControl) => {
    const formValues = Object.values(control.value);
    const isAtLeastOneSelected = formValues.some(Boolean);

    return isAtLeastOneSelected ? null : {atLeastOneSelected: true};
  };

  static isInTheFuture: ValidatorFn = (control: AbstractControl<Moment | null>) => {
    const isInThePast = moment(control.value)?.isBefore(moment());

    return isInThePast ? {dateInThePast: true} : null;
  };

  static allNestedFieldsSetAndNotEmpty: ValidatorFn = (control: AbstractControl<any>) => {
    return allFieldsSetAndNotEmpty(control.value) ? null : {someFieldsMissing: true};
  };

  static isDateInsideRange(dateTimeValue: Moment, range: DateTimeRange, compareTime = false): boolean {
    let startDateTime = MsaValidators.dateTimeMomentFromString(range.fromDate, range.fromTime);
    if (!range.fromTime || !compareTime) {
      startDateTime = startDateTime.startOf('day');
    }
    let endDateTime = MsaValidators.dateTimeMomentFromString(range.toDate, range.toTime);
    if (!range.toTime || !compareTime) {
      endDateTime = endDateTime.endOf('day');
    }

    return dateTimeValue.isBetween(startDateTime, endDateTime, 'minutes', '[]');
  }

  static validateDateContainedInRanges(
    date: Date | Moment,
    ranges: DateTimeRange[],
    compareTime = false
  ): {notInDutyRange: ValidationError} | null {
    const dateValue = moment(date);

    const isInRange = ranges.some(range => this.isDateInsideRange(dateValue, range, compareTime));

    return isInRange ? null : {notInDutyRange: createValidationError('i18n.leave.error.dateNotInDutyRange')};
  }

  private static dateTimeMomentFromString(date: string, time?: string): Moment {
    return time ? moment(`${date}, ${time}`, 'yyyy-MM-DD HH:mm') : moment(date).startOf('day');
  }

  public static isDateSameOrAfter(date: Moment, minDate: Moment | null): {isSameOrAfter: ValidationError} | null {
    if (!minDate) return null;
    return moment(date).isSameOrAfter(moment(minDate))
      ? null
      : {
          isSameOrAfter: createValidationError('i18n.common.error.datePrecedence', {
            referenceDate: minDate.format('DD.MM.YYYY HH:mm')
          })
        };
  }
}
